/* Hide scrollbar for Chrome and Firefox */

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */

body {
  -ms-overflow-style: none;
}

h1, h2, h3 {
  font-family: "consolas";
}

ul {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

a:link {
  color: #615055;
  text-decoration-color: #98ffcc;
}

a:visited {
  color: #615055;
  text-decoration-color: #98ffcc;
}

a:hover {
  color: #76c79f;
  text-decoration-color: #615055;
}

hr {
  border: none;
  background-color: #000000;
  color: #000000;
  height: 5px;
  margin: 40px;
}

.zoom {
  transition: transform .2s;
  /* Animation */
  width: 250px;
  margin-left: 10px;
  margin-top: 20px;
  z-index: 1
}

.zoom:hover {
  transform: scale(1.5);
  z-index: 2;
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}